/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

body {
  padding: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: rgb(211, 210, 210);
  box-sizing: border-box;
  margin: 0;
  height: 100vh;
  font-size: 12px;
  overflow: hidden;
}

.light {
  background: white;
}

.roomContainer {
  height: 100vh;
}

.error {
  color: red;
}

.lk-grid-layout-wrapper {
  height: 100%;
}

.lk-focus-layout {
  height: 100%;
  display: inline-flex;
  flex-direction: row-reverse;
}

.lk-carousel{
  width: 500px;
}

.lk-connection-quality{
  background-color: transparent;
  display: block;
}

.lk-participant-tile{
  display: block;
}

/* things like name, connection quality, etc make less sense in a recording, hide for now */
.lk-participant-metadata {
  /*display: none;*/
}

::-webkit-scrollbar {
  display: none;
}
